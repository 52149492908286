import React, { useContext } from "react";
import { NewClientContext } from "../../provider/NewClientProvider";
import Box from "@mui/material/Box";
import ButtonFullGrid from "./ButtonFullGrid";
import {
  DataGrid,
  esES,
  // GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
// **** linea 19
const columns = [
  { field: "fecha", headerName: "Fecha", width: 100},
  { field: "codigo", headerName: "Código", width: 60},
  { field: "rutcliente", headerName: "Rut", width: 120 },
  { field: "razonsocial", headerName: "Razon Social", width: 240 },
  { field: "local", headerName: "Local", width: 60 },
  { field: "direccion", headerName: "Dirección", width: 240 },
  { field: "comuna", headerName: "Comuna", width: 150 },
  { field: "telefono", headerName: "Teléfono", width: 100 },
  { field: "mail", headerName: "Correo", width: 150 },
  { field: "estado", headerName: "Estado", width: 100 } 
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <ButtonFullGrid />
      <GridPagination />
    </GridFooterContainer>
  );
}

export default function InfoFullGrid() {
  const { news, setModal, setSelected, theCliente, theGps } = useContext(NewClientContext);

  const handleRowClick = async (params) => {
    // console.log(params.row)
    await setSelected(params.row);
    await theCliente(params.row.rutcliente, params.row.local, params.row.fecha);
    await theGps(params.row.rutcliente, params.row.local, params.row.fecha);
    await setModal(true);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        sx={{ boxShadow: 2 }}
        components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
        rows={news}
        columns={columns}
        autoPageSize
        density="compact"
        onRowClick={handleRowClick}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
