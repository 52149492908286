import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NewClientProvider from "../../provider/NewClientProvider";
import InfoFullGrid from "./InfoFullGrid";
import MapDetail from "./MapDetail";

const style = { height: "calc(100vh - 70px)" };

export default function NewClient() {
    return (
        <NewClientProvider>
            <MapDetail />
            <Box sx={{ ...style, display: "flex" }}>
                <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                        <InfoFullGrid />
                    </Grid>
                </Grid>
            </Box>
        </NewClientProvider>
    );
}
