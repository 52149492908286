import React, { useContext } from "react";
import { CanalContext } from "./provider/CanalProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import MapDetailMap from "./MapDetailMap";
import MapDetailInfo from "./MapDetailInfo";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function MapDetail() {
  const { modal, setModal } = useContext(CanalContext);

  const handleClose = () => setModal(false);

  return (
    <>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={0.5} sx={{ height: "100%" }}>
            <Grid item xs={8}>
              <MapDetailMap />
            </Grid>
            <Grid item xs={4}>
              <MapDetailInfo />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
