import React, { useState, createContext, useEffect } from "react";
import dayjs from "dayjs";
export const CanalContext = createContext(null);
const today = dayjs(new Date()).format("YYYYMMDD")

export default function CanalProvider({ children }) {
  const [full, setFull] = useState(false);
  const [modal, setModal] = useState(false);
  const [canal, setCanal] = useState(null);
  const [visitas, setVisitas] =  useState([]);
  const [pedidos, setPedidos] =  useState([]);
  const [codigoFecha, setCodigoFecha] = useState({codigo: "9999", fecha: today});
  const [canalSelected, setCanalSelected] = useState([{ vendedores: "[]" }]);
  // const [consolidadoRuta, setConsolidadoRuta] = useState([]);
  const [modalConfig, setModalConfig] = useState(false);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [buscar, setBuscar] = useState(false);
  const [existe, setExiste] = useState(false);
  const [totales, setTotales] = useState([]);


// console.log("pase pormel provider canalprovider");
//console.log("visitas en prodiver", new Date().toISOString(), visitas);
// setTimeout(()=>console.log("visitas en prodiver - 400 mseg ==>",visitas, new Date().toISOString()), 400);

// useEffect(() => {
// console.log("provider cambio visitas", visitas);
// }, [visitas])


  return (
    <CanalContext.Provider
      value={{
        full, setFull,
        modal, setModal,
        canal, setCanal,
        visitas, setVisitas,
        pedidos, setPedidos,
        codigoFecha, setCodigoFecha,
        canalSelected, setCanalSelected,
        // consolidadoRuta, setConsolidadoRuta,
        modalConfig, setModalConfig,
        left, setLeft,
        right, setRight,
        buscar, setBuscar,
        existe, setExiste,
        totales, setTotales
      }}
    >
      {children}
    </CanalContext.Provider>
  );
}
