import React, { useContext } from "react";
import { NewClientContext } from "../../provider/NewClientProvider";
import { orangeMarker, greenMarker, blueMarker } from "../../utils/GeoIcons";
import { MapContainer } from "react-leaflet/MapContainer";
import { Marker } from "react-leaflet/Marker";
// import { Polyline } from "react-leaflet/Polyline";
import { TileLayer } from "react-leaflet/TileLayer";
// import { Tooltip } from "react-leaflet/Tooltip";
// import distance from "@turf/distance";
// import { listItemTextClasses } from "@mui/material";

export default function MapDetailMap() {
  const { selected, client, gps, setMapaDetalle } = useContext(NewClientContext);
  // const coordOk = selected.coordOk.toLowerCase() === "true" ? true : false;

  // let from = [client.lng, client.lat]; // LNG - LAT
  // let to = [selected.lng, selected.lat];
  // let dstnc = distance(from, to, { units: "meters" });

  let Bounds = [
    // [Number(client.lat), Number(client.lng)],
    [Number(selected.lat), Number(selected.lng)],
    // [Number(gps.lat), Number(client.lng)],
  ];

  return (
    <>
      <MapContainer bounds={Bounds} zoom={18} ref={setMapaDetalle}>
        <TileLayer url="https://mt1.google.com/vt/?lyrs=y&x={x}&y={y}&z={z}" />
        <Marker position={[selected.lat, selected.lng]} icon={blueMarker} />
      </MapContainer>
    </>
  );
}
