import React, { useContext } from "react";
import { CanalContext } from "../provider/CanalProvider";
import dayjs from "dayjs";
import "dayjs/locale/es";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function Fecha() {
  const { codigoFecha, setCodigoFecha } = useContext(CanalContext);
  // const [value, setValue] = useState(dayjs(new Date().toLocaleDateString("es-CL")));

// Creamos un objeto dayjs a partir de una fecha
//const fechaFormat = dayjs("20221012", "YYYYMMDD");

// Mostramos esa fecha con el formato indicado
// console.log("xxxxxx"); // "23/01/2022"
//const value = dayjs(codigoFecha?.fecha, "YYYYMMDD").format("DD/MM/YYYY")

  const handleChange = (newValue) => {
    //console.log("== newValue ==>", dayjs(newValue).format("YYYYMMDD"), newValue)
    //console.log(dayjs(codigoFecha?.fecha, "YYYYMMDD").format("DD/MM/YYYY"), "<<<=======")
    setCodigoFecha({...codigoFecha, fecha:dayjs(newValue).format("YYYYMMDD")});
    // setMapaCirculo(null)
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
      <DesktopDatePicker
        label="Fecha"
        inputFormat={"DD/MM/YYYY"}
        value={dayjs(codigoFecha?.fecha, "YYYYMMDD").format("MM/DD/YYYY")}
        onChange={handleChange}
        renderInput={(params) => <TextField size="small" {...params} sx={{ width: 150, marginRight:5}} />}
      />
    </LocalizationProvider>
  );
}
