import React, { useState, useEffect, useContext } from "react";
import {useNavigate} from "react-router-dom";
import { DataContext } from "../../../provider/DataProvider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getVendedores } from "../../../utils/queryvisitas";

export default function CodigoSales() {

  const [vendedores, setVendedores] = useState([{ codigo: "1704", nombre: "RODRIGO ESPINOZA" }]);

  const { codigoFecha, setCodigoFecha, setMapaCirculo } = useContext(DataContext);

  const allVendedores = async () => setVendedores(await getVendedores());
  const navigate = useNavigate();

  useEffect(() => {
    allVendedores();
  }, []);

  return (
    <Autocomplete
       onInputChange={(event, nIV) => {
          setCodigoFecha({ ...codigoFecha, codigo: nIV.slice(0, 4) })
          setMapaCirculo(null)
          // navigate("/map")
      }}
      disablePortal
      id="combo-box-demo"
      options={vendedores}
      getOptionLabel={(l) => l.codigo? `${l.codigo} - ${l.nombre}`: l.nombre}
      size="small"
      sx={{ width: 400, mx: 2 }}
      renderInput={(params) => <TextField {...params} label="Vendedor" />}
    />
  );
}
