import {useState, useEffect, useContext} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getCanales, getVendedores } from "../../utils/queryvisitas";
import { CanalContext } from "./provider/CanalProvider";

export default function MapDetailConfig() {

  const [canales, setCanales] = useState([])
  const [vendedores, setVendedores] = useState([])
  const { setLeft, setRight, setCanal } = useContext(CanalContext);

  const allCanales = async () => setCanales(await getCanales());
  const allVendedores = async () => setVendedores(await getVendedores()) 

  useEffect(() => {
    allVendedores();
    allCanales();
  }, []);

  ////////////////////////////////////////////////////////
  const canalLeft = []
  const canalRight = []

  function getCanal (canal) {
  const canalVendedores = JSON.parse(canal.vendedores)

  for (let vendedor of vendedores) {
        if (canalVendedores.find(v => v == vendedor.codigo )) {canalRight.push(vendedor)}
        else{ canalLeft.push(vendedor)}
  }
      setTimeout(() => {
        setLeft(canalLeft);
        setRight(canalRight);
      }, 500);
  }
////////////////////////////////////////////////////////


  return (
    <Autocomplete
    onInputChange={(event, nIC) => {
      setCanal(nIC)
      const match = canales.find(c => c.nombre === nIC);
      if (match) {getCanal(canales.find(c => c.nombre === nIC));}
      else{
        setLeft(vendedores);
        setRight([]);
      }     
    }}
      disablePortal
      // id="combo-box-demo"
      options={canales}
      getOptionLabel={l => l.nombre}
      sx={{ width: 600, marginBottom: 2 }}
      renderInput={(params) => <TextField {...params} label="Canales" />}
    />
  );
}