import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Login from "../pages/Login";
import Layout from "../pages/mapcenter/layout/Layout";
import Canales from "../pages/canales/Canales";
import MapCenter from "../pages/mapcenter/MapCenter";
import MapDetail from "../pages/mapcenter/MapDetail";
import NewClient from "../pages/newclient/NewClient";
import NotFound from "../pages/NotFound";
import LayoutCanal from "../pages/canales/layout/LayoutCanal";

export default function AppRouter() {
  return (
    <>
      <Routes>
    {/*///////////////////// RUTA VENDEDOR INDIVIDUAL /////////////////////*/}
        <Route element={<Layout />}>
          <Route path="map" element={<MapCenter />} />
          {/* <Route path="canales" element={<Canales />} />
          <Route path="canales/:urlCanal" element={<Canales />} /> */}
          <Route path="nuevos" element={<NewClient />} />
          <Route path="login" element={<Login />} />
          <Route path="map-detail" element={<MapDetail />} />
        </Route>
    {/*///////////////////// RUTA VENDEDORES POR CANAL /////////////////////*/}
        <Route element={<LayoutCanal />}>
          <Route path="canal" element={<Canales />} />
          <Route path="about" element={<About />} />
          {/* <Route path="/canal/:urlCanal" element={<Canales />} /> */}
        </Route>
    {/*///////////////////// RUTA SIN COINCIDENCIA /////////////////////*/}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  );
}
