import React, { useContext, useState, useEffect } from "react";
import { CanalContext } from "../provider/CanalProvider";
import "./Stats.css";

export default function Stats() {

    const { totales } = useContext(CanalContext);

    const [estadisticas, setEstadisticas] = useState({compra: '',nocompra: '',pendiente: ''});

    useEffect(() => {
        let pedidos = 0; let compra = 0;let nocompra = 0; let pendiente = 0; let tkilos = 0; let tneto = 0;

        totales.forEach(t =>{
        pedidos = pedidos + t.pedidos;
        compra = compra + t.compra;
        nocompra = nocompra + t.nocompra;
        pendiente = pendiente + t.pendiente;
        tkilos = tkilos + t.tkilos;
        tneto = tneto + t.tneto;
        })
        setEstadisticas({pedidos, compra , nocompra, pendiente, tkilos, tneto});
    }, [totales])


  return (
    <div className="container-stats">

{estadisticas.pedidos > 0
        ?
            <>
            <div className="button-pedido"><b>{estadisticas.pedidos}</b></div>
            <div className="btn button-ttl"><b>{(new Intl.NumberFormat('es-CL', {style: 'currency',currency: 'CLP'}).format(estadisticas.tneto))}</b></div>
            <div className="button-pedido"><b>K</b></div>
            <div className="btn button-ttl"><b>{parseFloat((estadisticas.tkilos)?.toFixed(2))}</b></div>
            {/* <div className="btn button-ttl"><b>{parseFloat((stats.kilos)?.toFixed(2))}</b> Kgs</div> */}
            <div className="button-pedido"><b>P</b></div>
            <div className="btn button-promedio"><b>{(new Intl.NumberFormat('es-CL', {style:'currency',currency:'CLP'}).format(estadisticas.tneto/estadisticas.pedidos))} - {parseFloat((estadisticas.tkilos/estadisticas.pedidos)?.toFixed(2))}</b> Kg</div>
            </>
         :
            null
}

        <div className="button button-green">{estadisticas.compra}</div> 
        <div className="button button-red">{estadisticas.nocompra}</div> 
        <div className="button button-blue">{estadisticas.pendiente}</div> 
    </div>
  )
}
