import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1080,
  bgcolor: "background.paper",
  border: "1px solid #000",
  alignContent: "flex-end",
  // boxShadow: 5,
  // pt: 2,
  // px: 4,
  // pb: 3,
};

export default function ImageDetail({ url }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <Box
          component="img"
          sx={{
            height: "20%",
            width: "20%",
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 350, md: 250 },
          }}
          alt="correcion"
          src={url}
        />
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 430 }}>
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
              // maxHeight: { xs: 233, md: 167 },
              // maxWidth: { xs: 350, md: 250 },
            }}
            alt="correcion"
            src={url}
          />
          <Grid container justifyContent="flex-end">
            <Button size="small" onClick={handleClose}>Cerrar</Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
