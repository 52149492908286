import React, { useContext } from "react";
import { DataContext } from "../../provider/DataProvider";
import Box from "@mui/material/Box";
import ButtonFullGrid from "./ButtonFullGrid";
import {
  DataGrid,
  esES,
  // GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
// **** linea 19
const columns = [
  { field: "secuencia", headerName: "#", width: 2},
  { field: "nvisita", headerName: "Visita", width: 2},
  { field: "rutcliente", headerName: "Rut", width: 100 },
  { field: "local", headerName: "Local", width: 60 },
  { field: "razonsocial", headerName: "Razon Social", width: 240 },
  { field: "status", headerName: "Status", width: 150 },
  { field: "comment", headerName: "Comentario", width: 480 },
  { field: "hora", headerName: "Hora", width: 80 },
  { field: "comuna", headerName: "Comuna", width: 150 }
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <ButtonFullGrid />
      <GridPagination />
    </GridFooterContainer>
  );
}

export default function InfoFullGrid() {
  const { sales, setModal, setSelected, theCliente, theGps } =
    useContext(DataContext);

  const handleRowClick = async (params) => {
    await setSelected(params.row);
    await theCliente(params.row.rutcliente, params.row.local, params.row.fecha);
    await theGps(params.row.rutcliente, params.row.local, params.row.fecha);
    await setModal(true);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        sx={{ boxShadow: 2 }}
        components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
        rows={sales}
        columns={columns}
        autoPageSize
        density="compact"
        onRowClick={handleRowClick}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
