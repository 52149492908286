import React, { useState, createContext } from "react";
import { getNuevoCliente, getGps, getCliente } from "../utils/queryvisitas";
import { useQuery } from "react-query";

const initialSales = [];
const initialSelected = { _id: "1",rutCliente: "",status: "",lat: "-33.333",lng: "-70.777"};
const initialGps = {rutcliente: "",local: "",lat: "",lng: "",fecha: "",hora: "",source: ""};
const initialClient = {rutcliente: "",local: "",direccion: "",razonsocial: "",lat: "",lng: "",codigo: "",fecha: ""};

export const NewClientContext = createContext(null);

export default function NewClientProvider({ children }) {
  const [full, setFull] = useState(false);
  const [estado, setEstado] = useState("pendiente")
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(initialSelected);
  const [client, setClient] = useState(initialClient);
  const [gps, setGps] = useState(initialGps);
  const [mapaDetalle, setMapaDetalle] = useState(null);

  const { data: news, isLoading } = useQuery(["nuevos"],() => getNuevoCliente(estado),
    { initialData: initialSales, refetchInterval: 10000 }
  );

  const theCliente = async (rutcliente, local, fecha) => {
    const resultClient = await getCliente(rutcliente, local, fecha);
    setClient(resultClient[0]);
  };

  const theGps = async (rutcliente, local, fecha) => {
    const resultGps = await getGps(rutcliente, local, fecha);
    // console.log("--__resultGps[0]__--", resultGps[0])
    setGps(resultGps[0]);
  };

  if (isLoading) {
    return (
      <div className="App">
        <h1>Esperando Nuevos Clientes ...</h1>
      </div>
    );
  }

  return (
    <NewClientContext.Provider
      value={{
        news,
        theGps,
        theCliente,
        gps, setGps,
        full, setFull,
        modal, setModal,
        estado, setEstado,
        client, setClient,
        selected, setSelected,
        mapaDetalle, setMapaDetalle
      }}
    >
      {children}
    </NewClientContext.Provider>
  );
}
