import React, { useState, createContext } from "react";
import { getVisitas, getCliente, getGps } from "../utils/queryvisitas";
import dayjs from "dayjs";
import { useQuery } from "react-query";

const initialSales = []; 
const initialSelected = { _id: "1",rutCliente: "",status: "",lat: "-33.333",lng: "-70.777"};
const initialClient = {rutcliente: "",local: "",direccion: "",razonsocial: "",lat: "",lng: "",codigo: "",fecha: ""};
const initialGps = {rutcliente: "",local: "",lat: "",lng: "",fecha: "",hora: "",source: ""};

const today = dayjs(new Date()).format("YYYYMMDD")

export const DataContext = createContext(null);

export default function DataProvider({ children }) {
  const [full, setFull] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(initialSelected);
  const [client, setClient] = useState(initialClient);
  const [gps, setGps] = useState(initialGps);
  const [mapaDetalle, setMapaDetalle] = useState(null);
  const [codigoFecha, setCodigoFecha] = useState({codigo: "9999", fecha: today});
  const [mapaCirculo, setMapaCirculo] = useState(null);
  //const [modalConfig, setModalConfig] = useState(false);
  // const [canal, setCanal] = useState(null);
  // const [visitas, setSseVisitas] =  useState([]);
  // const [pedidos, setSsePedidos] =  useState([]);  
  //visitas, setSseVisitas, pedidos, setsePedidos
  // const [canalSelected, setCanalSelected] = useState([{vendedores:"[]"}]);

  // const [consolidadoRuta, setConsolidadoRuta] = useState([])

  // const [left, setLeft] = useState([]);
  // const [right, setRight] = useState([]);

  const { data: sales, isLoading } = useQuery(["queryvisitas"],() => getVisitas(codigoFecha), { initialData: initialSales, refetchInterval: 2000 });

  const theCliente = async (rutcliente, local, fecha) => {
    const resultClient = await getCliente(rutcliente, local, fecha);
    setClient(resultClient[0]);
  };

  const theGps = async (rutcliente, local, fecha) => {
    const resultGps = await getGps(rutcliente, local, fecha);
    setGps(resultGps[0]);
  };

  if (isLoading) {
    return (
      <div className="App">
        <h1>Esperando ...</h1>
      </div>
    );
  }

  return (
    <DataContext.Provider
      value={{
        sales,
        // setSales,
        full,setFull,
        modal,setModal,
        selected,setSelected,
        client,setClient,
        theCliente,
        gps,setGps,
        theGps,
        mapaDetalle,setMapaDetalle,
        codigoFecha,setCodigoFecha,
        mapaCirculo,setMapaCirculo,
        // modalConfig,setModalConfig,
        // canal, setCanal,
        // visitas, setSseVisitas,
        // pedidos, setSsePedidos,
        // canalSelected, setCanalSelected,
        // left, setLeft,
        // right, setRight,
        // consolidadoRuta, setConsolidadoRuta
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
