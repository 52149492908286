import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { DataContext } from "../../provider/DataProvider";

export default function ButtonFullGrid() {
  const { full, setFull } = useContext(DataContext);

  const handleChange = () => setFull(!full);
  return (
    <>
      {
       full ? 
      <IconButton onClick={handleChange} variant="text">
        <ZoomInMapIcon />
      </IconButton>
      :
      <IconButton onClick={handleChange} variant="text">
        <ZoomOutMapIcon/>
      </IconButton>
      }
    </>
  );
}
