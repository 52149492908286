import React, { useContext } from "react";
import {URL_BASE} from "../../config";
import { DataContext } from "../../provider/DataProvider";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PublicIcon from "@mui/icons-material/Public";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ImageDetail from "./ImageDetail";
import { darken, lighten } from "@mui/material/styles";

export default function MapDetailInfo() {
  const { selected, client, gps, mapaDetalle, setModal } =
    useContext(DataContext);

  const coordOk = selected.coordOk.toLowerCase() === "true" ? true : false;

  const focusView = (lat, lng) => mapaDetalle.setView([lat, lng], 18);
  const focusBounds = () => {
    let Bounds = [
      [Number(client.lat), Number(client.lng)],
      [Number(selected.lat), Number(selected.lng)],
    ];

    !coordOk && Bounds.push([Number(gps.lat), Number(client.lng)]);

    // mapaDetalle.fitBounds([
    //   [Number(client.lat), Number(client.lng)],
    //   [Number(selected.lat), Number(selected.lng)],
    //   [Number(gps.lat), Number(gps.lng)],
    // ]);

    mapaDetalle.fitBounds(Bounds);
  };
  const handleClose = () => setModal(false);

  // const focusBounds = () => console.log("focusBounds");

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button size="small" onClick={handleClose}>
          Cerrar
        </Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%", mb: 2 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Rut
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Local
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Fecha
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center" padding="none">
                {selected.rutcliente}
              </TableCell>
              <TableCell align="center" padding="none">
                {selected.local}
              </TableCell>
              <TableCell align="center" padding="none">
                {dayjs(selected.fecha, "YYYYMMDD").format("DD/MM/YYYY")}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" colSpan={2} sx={{ fontWeight: "bold" }}>
                Direccion
              </TableCell>
              <TableCell align="center" colSpan={1} sx={{ fontWeight: "bold" }}>
                Comuna
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={2}>
                {client.direccion}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                {selected.comuna}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" colSpan={3} sx={{ fontWeight: "bold" }}>
                Estado: {selected.status}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>{selected.comment}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Table sx={{ width: "100%" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                <Chip label="Tipo" size="small" onClick={focusBounds} />
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Coordenadas
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                <TravelExploreIcon fontSize="small" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" padding="none">
                <Chip
                  label="Cliente"
                  color="primary"
                  size="small"
                  onClick={() => focusView(client.lat, client.lng)}
                />
              </TableCell>
              <TableCell align="center" padding="none">
                {Number.parseFloat(client.lat).toFixed(6)},{" "}
                {Number.parseFloat(client.lng).toFixed(6)}
              </TableCell>
              <TableCell align="center" padding="none">
                <IconButton
                  href={`https://maps.google.com/maps?q=${client.lat}%2C${client.lng}&t=h&z=19&hl=es`}
                  target="_blank"
                >
                  <PublicIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>

            { selected.status !== "No Visitado" ? 
            <TableRow>
            <TableCell align="center" padding="none">
              <Chip
                label="Visita"
                color="success"
                size="small"
                onClick={() => focusView(selected.lat, selected.lng)}
              />
            </TableCell>
            <TableCell align="center" padding="none">
              {Number.parseFloat(selected.lat).toFixed(6)},{" "}
              {Number.parseFloat(selected.lng).toFixed(6)}
            </TableCell>
            <TableCell align="center" padding="none">
              {" "}
              <IconButton
                href={`https://maps.google.com/maps?q=${selected.lat}%2C${selected.lng}&t=h&z=19&hl=es`}
                target="_blank"
              >
                <PublicIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>          
            : null
            }
            
            {!coordOk && gps ? (
              <TableRow>
                <TableCell align="center" padding="none">
                  <Chip
                    label={gps.source}
                    color="warning"
                    size="small"
                    onClick={() => focusView(gps.lat, gps.lng)}
                  />
                </TableCell>
                <TableCell align="center" padding="none">
                  {Number.parseFloat(gps.lat).toFixed(6)},{" "}
                  {Number.parseFloat(gps.lng).toFixed(6)}
                </TableCell>
                <TableCell align="center" padding="none">
                  <IconButton
                    href={`https://maps.google.com/maps?q=${gps.lat}%2C${gps.lng}&t=h&z=19&hl=es`}
                    target="_blank"
                  >
                    <PublicIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
              : null
          }

            {!coordOk && gps?.imagepath ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <ImageDetail
                  //  url={`https://ruteob-desarrollo.sanpabloalimentos.cl/${gps.imagepath}`}
                  url={`${URL_BASE}/${gps.imagepath}`}
                  />
                </TableCell>
              </TableRow>
            )
            : null
          }
            {!gps && !coordOk && selected.status !== "No Visitado" ?
             (
              <TableRow>
                <TableCell colSpan={3} align="center">
                <FmdBadIcon/>
                  <b>NO ingresó dato de corrección</b>
                </TableCell>
              </TableRow>
            )
          : null
          }

          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
