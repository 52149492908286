import { useContext } from "react";
import { CanalContext } from "../provider/CanalProvider";
import Button from "@mui/material/Button";

export default function Favoritos() {
    const { setModalConfig, setLeft, setRight } =  useContext(CanalContext);

  function inicio() {
    setLeft([]);
    setRight([]);
    setModalConfig(true)
  }

  return (
    <Button onClick={inicio} variant="contained" sx={{ my: 1, marginRight:"auto" }}>
    FAV
  </Button>
  )
}
