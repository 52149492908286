import React, { useContext } from "react";
import { CanalContext } from "./provider/CanalProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MapDetailConfig from "./MapDetailConfig";
import MapTransferList from "./MapTransferList";
import { saveCanal } from "../../utils/queryvisitas";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

export default function MapSellersGroup() {
  const { modalConfig, setModalConfig, canal, right } = useContext(CanalContext);

  const handleClose = () => setModalConfig(false)

  const save =() =>{
  const stringVendedores = right.map(r=>r.codigo);
   saveCanal({nombre:canal, vendedores:`[${stringVendedores.toString()}]`, privado:false, usuario:"admin" });
   handleClose();
  }

  return (
    <Modal
      open={modalConfig}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>

        <Box sx={style}>
          <Grid container spacing={0.5} sx={{ height: "100%" }}>
            <Grid item xs={12}>

              <Grid container justifyContent="flex-end">
                <Button size="small" onClick={handleClose}>Cerrar</Button>
              </Grid>

              <Grid container justifyContent="space-around">
              <MapDetailConfig />
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Privado" />
              </FormGroup>
              </Grid>

              <Grid container justifyContent="center">
                <MapTransferList/>
              </Grid>

              <Grid container justifyContent="center" marginTop={4}>
                <Button sx={{ marginX: 2 }} variant="contained" color="success" onClick={save}>Guardar</Button>
                <Button sx={{ marginX: 2 }} variant="contained" color="error" onClick={handleClose}>Eliminar</Button>
              </Grid>

            </Grid>
          </Grid>
        </Box>

      </>
    </Modal>
  );
}
