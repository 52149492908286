import React, { useContext } from "react";
import {URL_BASE} from "../../config";
import { NewClientContext } from "../../provider/NewClientProvider";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PublicIcon from "@mui/icons-material/Public";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ImageDetail from "./ImageDetail";

export default function MapDetailInfo() {
  const { selected, client, gps, mapaDetalle, setModal } = useContext(NewClientContext);

  const focusView = (lat, lng) => mapaDetalle.setView([lat, lng], 18);
  const focusBounds = () => {
    let Bounds = [
      [Number(client.lat), Number(client.lng)],
      [Number(selected.lat), Number(selected.lng)],
    ];

    mapaDetalle.fitBounds(Bounds);
  };
  const handleClose = () => setModal(false);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button size="small" onClick={handleClose}>
          Cerrar
        </Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%", mb: 2 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>Rut</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>Local</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="center" padding="none">{selected.rutcliente}</TableCell>
              <TableCell align="center" padding="none">{selected.local}</TableCell>
              <TableCell align="center" padding="none">{dayjs(selected.fecha, "YYYYMMDD").format("DD/MM/YYYY")}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" colSpan={2} sx={{ fontWeight: "bold" }}>Direccion</TableCell>
              <TableCell align="center" colSpan={1} sx={{ fontWeight: "bold" }}>Comuna</TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell align="center" colSpan={2}>{client.direccion}</TableCell>
              <TableCell align="center" colSpan={1}>{selected.comuna}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center" colSpan={3} sx={{ fontWeight: "bold" }}>Estado: {selected.estado}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={3}>{selected.comment}</TableCell>
            </TableRow>

          </TableBody>
        </Table>

        <Table sx={{ width: "100%" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                <Chip label="Tipo" size="small" onClick={focusBounds} />
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Coordenadas
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                <TravelExploreIcon fontSize="small" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
            <TableCell align="center" padding="none">
              <Chip label="Nuevo" color="primary" size="small" onClick={() => focusView(selected.lat, selected.lng)}/>
            </TableCell>
            <TableCell align="center" padding="none">
              {Number.parseFloat(selected.lat).toFixed(6)},{" "}
              {Number.parseFloat(selected.lng).toFixed(6)}
            </TableCell>
            <TableCell align="center" padding="none">
              {" "}
              <IconButton
                href={`https://maps.google.com/maps?q=${selected.lat}%2C${selected.lng}&t=h&z=19&hl=es`}
                target="_blank"
              >
                <PublicIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>          
            
            { gps?.imagepath ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <ImageDetail url={`${URL_BASE}/${gps.imagepath}`}  />
                </TableCell>
              </TableRow>
            )
            : null
          }

            <TableRow>
            <TableCell colSpan={3} align="center">
            <Button sx={{ mx:2, mt:2}} size="small" variant="contained" color="success" onClick={handleClose}>Guardar SAP</Button>
            <Button sx={{ mx:2, mt:2}} size="small" variant="contained" color="error" onClick={handleClose}>No Aprobado</Button>
            </TableCell>
          </TableRow> 

          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
