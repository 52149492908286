import React, { useState, useEffect, useContext } from "react";
// import {useNavigate} from "react-router-dom";
import { CanalContext } from "../provider/CanalProvider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCanales } from "../../../utils/queryvisitas";

export default function CodigoCanales() {

  const [selectCanales, setSelectCanales] = useState([]);
  const { codigoFecha, setCodigoFecha, setMapaCirculo, setCanalSelected } = useContext(CanalContext);

  const allCanales = async () => setSelectCanales(await getCanales());
  // const navigate = useNavigate();

  useEffect(() => {
    allCanales();
  }, []);

  return (
    <Autocomplete
       onInputChange={( _ , nIV) => {
        const encuentraCanal = selectCanales.find(c=> c.nombre === nIV)
        // console.log("encuentraCanal===>",encuentraCanal)
        if (encuentraCanal){
          setCanalSelected(encuentraCanal)
          // navigate(`/canal/${nIV}`)
        }
        else{
          setCodigoFecha({ ...codigoFecha, codigo: nIV.slice(0, 4) })
          // setMapaCirculo(null)
          // navigate("/map")
        }
      }
    }
      disablePortal
      id="combo-box-demo"
      options={selectCanales}
      getOptionLabel={(l) => l.codigo? `${l.codigo} - ${l.nombre}`: l.nombre}
      size="small"
      sx={{ width: 200, mx: 2 }}
      renderInput={(params) => <TextField {...params} label="Canal" />}
    />
  );
}
