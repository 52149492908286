import L from "leaflet";
import greenMarkerUrl from "./markers/marker-icon-2x-green.png";
import orangeMarkerUrl from "./markers/marker-icon-2x-orange.png";
import blueMarkerUrl from "./markers/marker-icon-2x-blue.png";
import shadowMarkerUrl from "./markers/marker-shadow.png";

export const greenMarker = new L.Icon({
  iconUrl: greenMarkerUrl,
  shadowUrl: shadowMarkerUrl,
  iconSize: [36, 60],
  iconAnchor: [12, 60],
  popupAnchor: [1, -34], // sin cambios
  shadowSize: [60, 60],
});

export const orangeMarker = new L.Icon({
  iconUrl: orangeMarkerUrl,
  shadowUrl: shadowMarkerUrl,
  iconSize: [36, 60],
  iconAnchor: [12, 60],
  popupAnchor: [1, -34], // sin cambios
  shadowSize: [60, 60],
});

export const blueMarker = new L.Icon({
  iconUrl: blueMarkerUrl,
  shadowUrl: shadowMarkerUrl,
  iconSize: [36, 60],
  iconAnchor: [12, 60],
  popupAnchor: [1, -34], // sin cambios
  shadowSize: [60, 60],
});