import React from "react";
import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import AppBarTop from "../menubar/AppBarTop";
import CanalProvider from "../provider/CanalProvider";

export default function LayoutCanal() {
  return (
    <>
      <CssBaseline />
      <Stack spacing={0}>
        <CanalProvider>
          <AppBarTop />
          <Outlet />
        </CanalProvider>
      </Stack>
    </>
  );
}