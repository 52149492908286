import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CodigoSales from "./CodigoSales";
// import CodigoCanales from "../../canales/menubar/CodigoCanales";
import FavoritosSales from "./FavoritosSales";
import FechaSales from "./FechaSales";
import StatsSales from "./StatsSales";

export default function AppBarTop() {
  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            San Pablo
          </Typography>
          {/* <FavoritosSales/> */}
          <StatsSales/>
           <CodigoSales />
           {/* <CodigoCanales /> */}
          <FechaSales />
          {/* <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
    </>
  );
}