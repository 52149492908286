import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../provider/DataProvider";
import "./StatsSales.css";

export default function StatsSales() {
    const { sales } = useContext(DataContext);
    // console.log("sales====>", sales)
    const [estadisticas, setEstadisticas] = useState({ conpedido: '', neto: '', compra: '', nocompra: '', pendiente: '' });
    useEffect(() => {
        const resultado = sales.reduce((acum, actual) => {
            if (actual.status === "Compra") {
                return { ...acum, compra: parseInt(acum.compra) + 1, kilos: acum.kilos + actual.kilos, neto: acum.neto + actual.neto, conpedido: actual.neto > 0 ? acum.conpedido + 1 : acum.conpedido }
            } else if (actual.status === "No Visitado") {
                return { ...acum, pendiente: parseInt(acum.pendiente) + 1 }
            } else {
                return { ...acum, nocompra: parseInt(acum.nocompra) + 1 }
            }
        }
            , { kilos: 0, conpedido: 0, neto: 0, compra: 0, nocompra: 0, pendiente: 0 }
        );

        setEstadisticas(resultado);

    }, [sales])


  return (
    <div className="container-stats">

{estadisticas.conpedido
                ?
                <>
                    <div className="button-pedido"><b>{estadisticas.conpedido}</b></div>
                    <div className="btn button-ttl"><b>{(new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(estadisticas.neto))}</b></div>
                    <div className="button-pedido"><b>K</b></div>
                    <div className="btn button-ttl"><b>{parseFloat((estadisticas.kilos)?.toFixed(2))}</b></div>
                    {/* <div className="btn button-ttl"><b>{parseFloat((estadisticas.kilos)?.toFixed(2))}</b> Kgs</div> */}
                    <div className="button-pedido"><b>P</b></div>
                    <div className="btn button-promedio"><b>{(new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(estadisticas.neto / estadisticas.conpedido))} - {parseFloat((estadisticas.kilos / estadisticas.conpedido)?.toFixed(2))}</b> Kg</div>
                </>
                :
                null
            }

        <div className="button button-green">{estadisticas.compra}</div> 
        <div className="button button-red">{estadisticas.nocompra}</div> 
        <div className="button button-blue">{estadisticas.pendiente}</div>
    </div>
  )
}
