import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CodigoCanales from "./CodigoCanales";
import Favoritos from "./Favoritos";
import Fecha from "./Fecha";
import Stats from "./Stats";
import BtnBucar from "./BtnBuscar";
import { useParams } from "react-router-dom";

export default function AppBarTop() {

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            San Pablo
          </Typography>
          <Favoritos/>
          <Stats/>
          <CodigoCanales />
          <Fecha />
          <BtnBucar />
          {/* <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
    </>
  );
}