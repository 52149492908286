import React, { useContext } from "react";
import { DataContext } from "../../provider/DataProvider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MapView from "./MapView";
import MapInfo from "./MapInfo";
import InfoFullGrid from "./InfoFullGrid";
import MapDetail from "./MapDetail";
import MapSellersGroup from "./MapSellersGroup";

const style = { height: "calc(100vh - 70px)" };

export default function MapCenter() {
  const { full } = useContext(DataContext);
  return (
    <>
      <MapDetail />
      {/* <MapSellersGroup /> */}
      <Box sx={{ ...style, display: "flex" }}>
        {full ? (
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <InfoFullGrid />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={0.5}>
            <Grid item xs={4}>
              <MapInfo />
            </Grid>
            <Grid item xs={8}>
              <MapView />
            </Grid>
          </Grid>
        )}
      </Box>
      </>
  );
}
