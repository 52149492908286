import React, { useContext, useEffect } from "react";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonFullGrid from "./ButtonFullGrid";
// import RenderCellExpand from "./RenderCellExpand";
import { CanalContext } from "./provider/CanalProvider";
import { DataGrid, esES, GridFooterContainer, GridPagination } from "@mui/x-data-grid";

const getBackgroundColor = (color, mode) => mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getHoverBackgroundColor = (color, mode) => mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

// function logWithTimestamp(message) {
//   const timestamp = new Date().toISOString();
// console.log(`[${timestamp}] ${message}`);
// }

function CustomFooter() {
  return (
    <GridFooterContainer>
      <ButtonFullGrid />
      <GridPagination />
    </GridFooterContainer>
  );
}
/*
const rowsTest = [
  { codigo: "1704", clientes: 99, compra: 33, nocompra: 33, pendiente: 33, pedidos: 5, tneto: 999999, tkilos: 99, pneto: 99, pkilos: 99  },
  { codigo: "1794", clientes: 99, compra: 10, nocompra: 33, pendiente: 33, pedidos:7, tneto: 999999, tkilos: 99, pneto: 99, pkilos: 99 }
]
*/
const columns = [
  { field: "codigo", headerName: "Cod", headerAlign: "center", align: "center", width: 55 },
  // { field: "nombre", headerName: "Nombre", renderCell: RenderCellExpand },
  { field: "clientes", headerName: "Cli", headerAlign: "center", align: "center", width: 50 },
  { field: "compra", headerName: "C", headerAlign: "center", align: "center", width: 50 },
  { field: "nocompra", headerName: "NC", headerAlign: "center", align: "center", width: 50 },
  { field: "pendiente", headerName: "NV", headerAlign: "center", align: "center", width: 40 },
  { field: "pedidos", headerName: "Ped", headerAlign: "center", align: "center", width: 50 },
  { field: "tneto", headerName: "$Neto", headerAlign: "center", width: 80, renderCell: (value)=>(Math.round(value.row.tneto)).toLocaleString("es-CL")},
  { field: "tkilos", headerName: "Kilos", headerAlign: "center", align: "center", width: 50, renderCell: (value)=>(Math.round(value.row.tkilos)).toLocaleString("es-CL")},
  { field: "pneto", headerName: "$Neto-Pr", headerAlign: "center", align: "center", width: 70, renderCell: (value)=>(Math.round(value.row.pneto)).toLocaleString("es-CL")},
  { field: "pkilos", headerName: "Kilos-Pr", width: 70, headerAlign: "center", align: "center", renderCell: (value)=>(Math.round(value.row.pkilos)).toLocaleString("es-CL")},
];

export default function MapInfo() {

  const { totales } = useContext(CanalContext);

  useEffect(() => {

  }, [totales]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        "& .super-app-theme--true": {
          bgcolor: (theme) => getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": { bgcolor: (theme) => getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode) },
        },
      }}
    >
      { totales && (
        <DataGrid
          getRowId={(row) => row.codigo}
          sx={{ boxShadow: 2 }}
          components={{ Footer: CustomFooter }}
          rows={totales}
          columns={columns}
          autoPageSize
          density="compact"
          // onRowClick={handleRowClick}
          // getRowClassName={(params) => `super-app-theme--${params.row.coordOk}`}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      )}

      {/* {JSON.stringify(rows)} */}
    </Box>

  );
}