import React, { useContext } from "react";
import { DataContext } from "../../provider/DataProvider";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ButtonFullGrid from "./ButtonFullGrid";
import {
  DataGrid,
  esES,
  // GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const RenderSecuencia = ({secuencia, x, y }) => {
  // activa valor global del punto 
  const { setMapaCirculo } =  useContext(DataContext);
  return (
    <IconButton 
    variant="outlined" 
    size="small"
    onClick={(e)=>{
      e.stopPropagation();
      setMapaCirculo([x,y]);
    }
    }
    >{secuencia}</IconButton>
  )

}

const columns = [
  { field: "secuencia", headerName: "#", width: 2, renderCell: s =>  s.row.secuencia && <RenderSecuencia secuencia={s.row.secuencia} x={s.row.lat} y={s.row.lng} />},
  { field: "nvisita", headerName: "Visita", width: 2},
  { field: "rutcliente", headerName: "Rut",width: 95 },
  { field: "local", headerName: "Loc", width: 30},
  { field: "status", headerName: "Status", width: 100 },
  { field: "hora", headerName: "Hora", width: 60, renderCell: h => h.row.hora && (h.row.hora).slice(0,5) },
  { field: "comuna", headerName: "Comuna", width: 80 },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {/* <ButtonFullGrid /> */}
    </GridToolbarContainer>
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer>
      <ButtonFullGrid />
      <GridPagination />
    </GridFooterContainer>
  );
}

export default function MapInfo() {
  const { sales, setModal, setSelected, theCliente, theGps } =
    useContext(DataContext);

  const handleRowClick = async (params) => {
    await setSelected(params.row);
    await theCliente(params.row.rutcliente, params.row.local, params.row.fecha);
    await theGps(params.row.rutcliente, params.row.local, params.row.fecha);
    await setModal(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        "& .super-app-theme--true": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      {sales && (
        <DataGrid
          getRowId={(row) => row._id}
          sx={{ boxShadow: 2 }}
          components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
          rows={sales}
          columns={columns}
          autoPageSize
          density="compact"
          onRowClick={handleRowClick}
          getRowClassName={(params) => `super-app-theme--${params.row.coordOk}`}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      )}
    </Box>
  );
}
