import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import DataProvider from "../../../provider/DataProvider";
import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CssBaseline from "@mui/material/CssBaseline";
import AppBarTop from "../menubar/AppBarTop";

const queryClient = new QueryClient();

export default function Layout() {
  return (
    <>
      <CssBaseline />
      <Stack spacing={0}>
        <QueryClientProvider client={queryClient}>
          <DataProvider>
            <AppBarTop />
            <Outlet />
          </DataProvider>
        </QueryClientProvider>
      </Stack>
    </>
  );
}